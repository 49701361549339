import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

async function genDocument7(values: any) {
  const url = '/nimbus-roman.otf'
  const nimbusRomanBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.create()
  pdfDoc.registerFontkit(fontkit)
  const nimbusRomanFont = await pdfDoc.embedFont(nimbusRomanBytes)

  const page = pdfDoc.addPage()
  const { width, height } = page.getSize()
  const fontSize = 14

  const text = `
    ${values?.regdate ?? '-'}                                                                     Рег. номер: ${values?.regnum ?? '-'}
                                                   
    
                                                  Акт технической экспертизы
    
    ${values?.regtext}
  `

  page.drawText(text, {
    x: 50,
    maxWidth: width - 80,
    y: height - height / 12,
    size: fontSize,
    font: nimbusRomanFont,
    color: rgb(0, 0, 0),
  })

  

  const pdfBytes = await pdfDoc.save()
  
  return pdfBytes
}

export default genDocument7;