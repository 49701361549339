import {
    Form,
    Button,
    Select,
    Typography,
    Result
} from "antd";

import { useState} from "react";
import { useForm } from "antd/lib/form/Form";
import {
    signularisApi,
    useCreateMetaDocumentMutation,
    useGetRecipientsListQuery
} from "../../../store/api/signularisApi";

import { useAppDispatch } from "../../../hooks/redux";
import {CREATE_DOCUMENT_ROUTE, MAIN_PAGE_ROUTE} from "../../../routing/consts";
import { Link } from "react-router-dom";
import {DocTemplateType} from "../../../types/types";
import genDocument7 from "../misc/gendoc7";
import TextArea from "antd/lib/input/TextArea";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

type TemplateRequest = {
    title:      string,
    brief:      string,
    recipients: string[],
    file:       File,
    reference: number,
    regdate: string,
    regnum: string,
    regtext: string,
    dutyface: string
}

//generates random id;
let guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x100)
            .toString(10)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + s4() + s4() + s4();
}

function styledDate() {
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", 
                    "августа", "сентября", "октября", "ноября", "декабря"]
    const dobj = new Date()
    return `${dobj.getDate()} ${months[dobj.getMonth()]} ${dobj.getFullYear()}`
  }


function Template() {
    
    const dispatch = useAppDispatch()

    const { data: recipientsList } = useGetRecipientsListQuery()


    const [docname, setDocname] = useState("")
    const docTemplate : DocTemplateType = 'technicalExaminationReport'

    const [createMetaDocument, {
        isLoading: mutationLoading,
        isSuccess: mutationSuccess,
        isError: mutationError,
    } ] = useCreateMetaDocumentMutation()

    const [form] = useForm()

    const onFinish = async (values: TemplateRequest) => {
        // user info
        const regnum = guid()

        setDocname('Акт технической экспертизы')
        const formData = new FormData()
        formData.append("title", `Акт технической экспертизы №${regnum}`)
        formData.append("brief", "Сгенерировано из шаблона")
        formData.append("recipients", values.recipients.join(","))
        formData.append("documentType", docTemplate)
        values.regnum = regnum
        values.regdate = styledDate()
        const pdfBytes = await genDocument7(values)
        formData.append("file", new Blob([pdfBytes]))
        // downloadPdfFileResponse(new Blob([pdfBytes]));
        createMetaDocument(formData)
        dispatch(signularisApi.util.invalidateTags(['MetaDocument']))
    }

    if (mutationSuccess) {
        return (
            <Result
                status="success"
                title="Документ создан"
                subTitle={`Ваш документ ${docname} успешно создан из шаблона 'Акт технической экспертизы'.`}
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
                ]}
            />
        )
    }

    if (mutationError) {
        return (
            <Result
                status="error"
                title="Не удалось создать документ"
                extra={[
                    <Link to={MAIN_PAGE_ROUTE + '/' + CREATE_DOCUMENT_ROUTE}><Button >Создать еще</Button></Link>
                ]}
            />
        )
    }


    return (
        <>
            <Title level={2} style={{ marginBottom: '30px' }}>Акт технической экспертизы</Title>
            <Form form={form} onFinish={onFinish} initialValues={{regtext: "Отделом программно-технического обеспечения ИАУ ГГНТУ в лице"}}>
                <Item name="recipients" rules={[{ required: true, message: 'Обязательное поле' }]}>
                    <Select
                        mode="multiple"
                        maxTagCount='responsive'
                        allowClear
                        placeholder="Получатели"
                        notFoundContent={"Ничего не найдено"}
                        filterOption={(input, option) =>
                            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option?.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            recipientsList?.map((recipient) => (
                                <Option key={recipient.id} value={recipient.id} label={recipient.fullname}>
                                    {recipient.fullname} {!!recipient.position && <>- <span style={{color: 'gray'}}>{recipient.position}</span></>}
                                </Option>
                            ))
                        }
                    </Select>
                </Item>
                <Item className='my-input' name="regtext" rules={[{ required: true, message: 'Обязательное поле' }]}>
                    <TextArea placeholder="Продолжение текста заявки" rows={5}/>
                </Item>
                <Button className='my-btn margin-bottom-xl' type="primary"  htmlType="submit" loading={mutationLoading}>Создать документ</Button>
            </Form>
        </>
    )
}

export default Template;