import { DocTemplateTypeObj} from "./types/types";

export const documentTemplates: DocTemplateTypeObj = {
    extractStorage: 'Выписка из склада',
    partTimeWork: 'Заявление о выходе на работу в условиях неполного рабочего времени',
    default: '',
    paidLeave: 'Заявление на оплачиваемый отпуск',
    passToGGNTU: 'Заявка на пропуск в корпуса ГГНТУ',
    deliveryNote: 'Требование-накладная',
    purchaseOfService: 'Заявка на приобретение товара/оказание услуг/выполнение работ',
    technicalExaminationReport: 'Акт технической экспертизы',
    applicationForPurchase: 'Заявка на приобретение',
}

